@import '~@src/styles/bootstrap/_utils';

ul.navBar {
  padding-bottom: 1rem; /* 14/14 */
  border-bottom: 0.071rem solid $aliadaGrayLight; /* 1/14 */

  li:not(:last-child) {
    margin-right: 2.857rem; /* 40/14 */
  }
}

