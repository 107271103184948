@import '~@src/styles/bootstrap/_utils';

.navLink {
  padding-bottom: 1rem; /* 14/14 */
  list-style: none;
  cursor: pointer;

  &:hover {
    color: $aliadaGrayLight; /* 1/14 */
  }
}

.navLinkActive {
  color: $black !important;
  border-bottom: 0.214rem solid black; /* 3/14 */
}

