$icomoon-font-path: "/fonts/icons";

$icon-ruler: "\e92b";
$icon-search: "\e92a";
$icon-ellipsis-circle: "\e929";
$icon-giftbox: "\e928";
$icon-auction: "\e926";
$icon-truck: "\e927";
$icon-box: "\e925";
$icon-pending: "\e922";
$icon-delivered: "\e923";
$icon-unavailable: "\e924";
$icon-clock: "\e900";
$icon-star-full: "\e901";
$icon-star: "\e902";
$icon-report: "\e903";
$icon-product: "\e904";
$icon-crate: "\e905";
$icon-check: "\e906";
$icon-wallet: "\e907";
$icon-basket: "\e908";
$icon-briefcase: "\e909";
$icon-bell: "\e90a";
$icon-wrench: "\e90b";
$icon-location: "\e90c";
$icon-pencil: "\e90d";
$icon-download: "\e90e";
$icon-trash: "\e90f";
$icon-calendar: "\e910";
$icon-gear: "\e911";
$icon-comment: "\e912";
$icon-close: "\e913";
$icon-spray: "\e914";
$icon-chevron-circle-right: "\e915";
$icon-chevron-circle-left: "\e916";
$icon-chevron-left: "\e917";
$icon-chevron-right: "\e918";
$icon-chevron-up: "\e919";
$icon-chevron-down: "\e91a";
$icon-check-circle: "\e91b";
$icon-catalog: "\e91c";
$icon-cart: "\e91d";
$icon-person: "\e91e";
$icon-plus: "\e91f";
$icon-clip: "\e920";
$icon-minus: "\e921";
$icon-no-quotes: "\e92c";
$icon-no-services: "\e92d";
$icon-no-activity: "\e92e";
$icon-home: "\e92f";
$icon-invoices: "\e930";
$icon-copy: "\e931";
$icon-pin: "\e933";

@font-face {
  font-family: 'tandem-icons';
  src:  url('#{$icomoon-font-path}/tandem-icons.eot?4cz1uy');
  src:  url('#{$icomoon-font-path}/tandem-icons.eot?4cz1uy#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/tandem-icons.ttf?4cz1uy') format('truetype'),
    url('#{$icomoon-font-path}/tandem-icons.woff?4cz1uy') format('woff'),
    url('#{$icomoon-font-path}/tandem-icons.svg?4cz1uy#tandem-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tandem-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ruler {
  &:before {
    content: $icon-ruler;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-ellipsis-circle {
  &:before {
    content: $icon-ellipsis-circle;
  }
}
.icon-giftbox {
  &:before {
    content: $icon-giftbox;
  }
}
.icon-auction {
  &:before {
    content: $icon-auction;
  }
}
.icon-truck {
  &:before {
    content: $icon-truck;
  }
}
.icon-box {
  &:before {
    content: $icon-box;
  }
}
.icon-pending {
  &:before {
    content: $icon-pending;
  }
}
.icon-delivered {
  &:before {
    content: $icon-delivered;
  }
}
.icon-unavailable {
  &:before {
    content: $icon-unavailable;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-report {
  &:before {
    content: $icon-report;
  }
}
.icon-product {
  &:before {
    content: $icon-product;
  }
}
.icon-crate {
  &:before {
    content: $icon-crate;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet;
  }
}
.icon-basket {
  &:before {
    content: $icon-basket;
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase;
  }
}
.icon-bell {
  &:before {
    content: $icon-bell;
  }
}
.icon-wrench {
  &:before {
    content: $icon-wrench;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-gear {
  &:before {
    content: $icon-gear;
  }
}
.icon-comment {
  &:before {
    content: $icon-comment;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-spray {
  &:before {
    content: $icon-spray;
  }
}
.icon-chevron-circle-right {
  &:before {
    content: $icon-chevron-circle-right;
  }
}
.icon-chevron-circle-left {
  &:before {
    content: $icon-chevron-circle-left;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}
.icon-catalog {
  &:before {
    content: $icon-catalog;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-clip {
  &:before {
    content: $icon-clip;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-no-quotes {
  &:before {
    content: $icon-no-quotes;
  }
}
.icon-no-services {
  &:before {
    content: $icon-no-services;
  }
}
.icon-no-activity {
  &:before {
    content: $icon-no-activity;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-invoices {
  &:before {
    content: $icon-invoices;
  }
}
.icon-copy {
  &:before {
    content: $icon-copy;
  }
}
.icon-pin {
  &:before {
    content: $icon-pin;
  }
}
